import styled from "styled-components"
import { device } from "breakpoints"
import { Grid, Row, Col } from "UI/Grid"

export const HomeTemplateContainer = styled.div`
  padding-top: 18px;
  @media ${device.desktop} {
    padding-top: 0px;
  }
`
export const HomeGrid = styled(Grid)``
export const ResultsCount = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #303b45;
  margin-bottom: 24px;

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`

export const PaginationAndResults = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  @media ${device.desktop} {
    padding-top: 40px;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const RightCol = styled(Col)`
  @media ${device.large} {
    padding-left: ${(props) => props.hasResults && "45px"} !important;
  }
`
