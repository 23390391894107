import React, { useState, useEffect } from "react"
import { FiltersContainer, TriggerAndTitle, FiltersListWrapper } from "./style"
import { ReactComponent as TitleArrow } from "icons/arrow-in-circle.svg"
import { ReactComponent as TitleCross } from "icons/cross-in-circle.svg"
import FiltersSection from "./FiltersSection"
import { AuthorModule } from "./AuthorModule"
import { ReactComponent as DesktopArrow } from "icons/refine-arrow.svg"

import { useStaticQuery, graphql } from "gatsby"

const Filters = ({ updateFilters, setFilters, filters }) => {
  const [filtersShown, setFiltersShown] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        wgTopics: wpCategory(name: { eq: "Workplace Giving Topics" }) {
          name
          slug
          wpChildren {
            nodes {
              name
              slug
              wpChildren {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
        otherTopics: wpCategory(name: { eq: "Other Topics" }) {
          name
          slug
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }

        authors: wp {
          getSearchFilter {
            author
            year
            type
            country
          }
        }
      }
    `
  )

  const countries = data.authors?.getSearchFilter?.country.map((country) => ({
    name: country,
    slug: country,
    type: "country",
  }))
  const years = data.authors?.getSearchFilter?.year.map((year) => ({
    name: year,
    slug: year,
    type: "year",
  }))
  const types = data.authors?.getSearchFilter?.type.map((type) => ({
    name: type,
    slug: type,
    type: "type",
  }))
  const authors = data.authors?.getSearchFilter?.author.map((author) => ({
    name: author,
    slug: author,
    type: "author",
  }))
  const others = data?.otherTopics?.wpChildren?.nodes.map((other) => ({
    ...other,
    type: "category",
  }))

  const wgTopics = data?.wgTopics?.wpChildren?.nodes.map((other) => ({
    ...other,
    type: "category",
  }))

 

  // get params
  useEffect(() => {
    let allFilters = []
    allFilters = [
      ...wgTopics,
      ...others,
      ...authors,
      ...countries,
      ...years,
      ...types,
    ]

    wgTopics.forEach((filter) => {
      if (filter.wpChildren.nodes.length) {
        filter.wpChildren.nodes.map((childFilter) => {
          allFilters.push({ ...childFilter, type: "category" })
        })
      }
    })

    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search)
      const filtersParam = urlParams?.get("f")?.split("|")
      console.log("filtersParam:", filtersParam)

      if (filtersParam?.length) {
        const filtersArray = filtersParam
          .filter((item) => item !== "")
          .map((filterSlug) => {
            return allFilters.find(
              (filter) =>
                filter?.slug === filterSlug || filter?.value === filterSlug
            )
          })

        // console.log("allFilters:", allFilters)
        // console.log("filtersArray:", filtersArray)

        if (filtersArray?.length) {
          setFilters(filtersArray)
        }
      }
    }
  }, [])

  return (
    <FiltersContainer filtersShown={filtersShown}>
      <TriggerAndTitle onClick={() => setFiltersShown(!filtersShown)}>
        <DesktopArrow className="desktop-arrow" /> Refine your results{" "}
        {filtersShown ? <TitleCross /> : <TitleArrow />}
      </TriggerAndTitle>

      <FiltersListWrapper filtersShown={filtersShown}>
        <AuthorModule
          updateFilters={updateFilters}
          filters={filters}
          authors={authors}
        />
        <FiltersSection
          title="Workplace Giving"
          list
          updateFilters={updateFilters}
          filters={filters}
          filtersList={data.wgTopics.wpChildren.nodes}
        />
        <FiltersSection
          title="Other Subjects"
          other
          updateFilters={updateFilters}
          filters={filters}
          filtersList={others}
        />
        <FiltersSection
          title="Year"
          select
          data={years}
          filters={filters}
          updateFilters={updateFilters}
        />
        <FiltersSection
          title="Publication Type"
          other
          updateFilters={updateFilters}
          filters={filters}
          filtersList={types}
        />
        <FiltersSection
          title="Country of Origin"
          select
          filters={filters}
          data={countries}
          updateFilters={updateFilters}
        />
      </FiltersListWrapper>
    </FiltersContainer>
  )
}

export default Filters
