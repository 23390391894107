import React from "react"
import { PopularResourcesContainer, ResourcesTeaser } from "./style"
import { Grid, Row, Col } from "UI/Grid"
import AvenueLink from "UI/AvenueLink"
import { ReactComponent as Arrow } from "icons/arrow-in-circle.svg"
import { WhiteButton } from "components/globalComponents"
const PopularResources = ({ popularResources, findResults }) => {
  const handleClick = () => {
    findResults()
    document.querySelector("#header-input").scrollIntoView({
      behavior: "smooth",
    })
  }
  return (
    <PopularResourcesContainer>
      <h3>Popular Resources</h3>
      <Grid>
        <Row mdGap={27} mdRowGap={20} lgGap={27} lgRowGap={20}>
          {popularResources &&
            popularResources?.map((post, index) => {
              return (
                <Col key={index} xs={12} sm={6}>
                  <ResourcesTeaser
                    key={index}
                    title={post.title}
                    excerpt={post.excerpt}
                    noImg
                  >
                    <AvenueLink to={post.uri}>
                      <Arrow /> View more
                    </AvenueLink>
                  </ResourcesTeaser>
                </Col>
              )
            })}
        </Row>
      </Grid>
      <WhiteButton label="View all articles" onClick={() => handleClick()} />
    </PopularResourcesContainer>
  )
}

export default PopularResources
