import React, { useState, useRef } from "react"
import { AuthorModuleContainer, AuthorGrid, AuthorItem } from "./style"
import { FiltersSectionTitle } from "../style"
import { ReactComponent as AuthorIcon } from "icons/author-icon.svg"
import { Row, Col } from "UI/Grid"
import { useOutsiderAlerter } from "helpers/useOutsiderAlerter"
import { useEscHandler } from "helpers/useEscHandler"

const AuthorModule = ({ updateFilters, filters, authors }) => {
  const [open, setOpen] = useState(false)
  const authorPopUpRef = useRef(null)
  useOutsiderAlerter(authorPopUpRef, () => setOpen(false))
  useEscHandler(() => setOpen(false))

  return (
    <AuthorModuleContainer ref={authorPopUpRef}>
      <FiltersSectionTitle onClick={() => setOpen(!open)}>
        Author <AuthorIcon />
      </FiltersSectionTitle>

      <AuthorGrid open={open}>
        <Row xsGap={30} xsRowGap={10}>
          {authors?.map((filter, index) => {
            
            return (
              <Col key={index}>
                <AuthorItem
                  filter={filter}
                  updateFilters={updateFilters}
                  filters={filters}
                />
              </Col>
            )
          })}
        </Row>
      </AuthorGrid>
    </AuthorModuleContainer>
  )
}

export default AuthorModule
