import React, { useEffect, useContext, useCallback, useState } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { MainColumn } from "UI/MainColumn"
import { Row, Col } from "UI/Grid"
import { PopularResources } from "../../Project/PopularResources"
import { Filters } from "../../Project/Filters"
import { Pagination } from "UI/Pagination"
import { useMedia } from "helpers/useMedia.js"
import { SearchResults } from "../../Project/SearchResults"
import { FilterResults } from "../../Project/Filters/FilterResults"
import history from "UI/Pagination/history"


import {
  HomeTemplateContainer,
  HomeGrid,
  PaginationAndResults,
  ResultsCount,
  RightCol,
} from "./style"
import { useDidMountEffect } from "helpers/useDidMountEffect"
import algoliaIndex from "./algoliaIndex"

const HomeTemplate = ({ pageData }) => {
  
  

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )

  const [query, setQuery] = useState("")
  const [filters, setFilters] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(null)
  const [totalPosts, setTotalPosts] = useState(null)
  const [totalPages, setTotalPages] = useState(0)
  const postsPerPage = 10

  const updateFilters = (value) => {

    console.log(filters)
    const filtersIncludesValue = filters.find(
      (filter) => filter.slug === value.slug
    )
    const isSelect = value.type === "year" || value.type === "country"

    const otherFilters = filters.filter((item) => item.slug !== value.slug)
    
    setCurrentPage(1)
    if (filtersIncludesValue) {
      setFilters([...otherFilters])
    } else if (isSelect) {
      const otherFilterTypes = filters.filter(
        (item) => item.type !== value.type
      )
      setFilters([...otherFilterTypes, value])
    } else {
      setFilters([...otherFilters, value])
    }
  }

  const findResults = useCallback(() => {


    
    // const authorFilters = filters
    const catFacets = filters
      .filter((filter) => filter?.type === "category")
      .map((filter) => `categoriesSlug: ${filter?.slug}`)


      console.log(filters);
    // console.log("catFacets:", catFacets)

    const authorFacets = filters
      .filter((filter) => filter?.type === "author")
      .map((filter) => `authors: ${filter?.name}`)
    // console.log("authorFacets:", authorFacets)

    const countryFacets = filters
      .filter((filter) => filter?.type === "country")
      .map((filter) => `pb_country: ${filter?.name}`)
    // console.log("countryFacets:", countryFacets)

    const yearFacets = filters
      .filter((filter) => filter?.type === "year")
      .map((filter) => `pb_year: ${filter?.name}`)
    // console.log("yearFacets:", yearFacets)

    const typeFacets = filters
      .filter((filter) => filter?.type === "type")
      .map((filter) => `pb_type: ${filter?.name}`)
    // console.log("typeFacets:", typeFacets)


    pushToHistory(currentPage, filters)

    console.log('seraching: ', query,catFacets)

    algoliaIndex
      .search(query, {
        page: currentPage - 1,
        hitsPerPage: postsPerPage,
        facetFilters: [
          [
            ...catFacets,
            ...authorFacets,
      
          ],
          ...countryFacets,
          ...yearFacets,
          ...typeFacets,
        ],
      })
      .then((data) => {
        // console.log('data:', data)
        setCurrentPosts(data.hits)
        setTotalPosts(data.nbHits)
        setTotalPages(data.nbPages)
      })
  }, [currentPage, query, filters])

  const pushToHistory = (page, filters) => {
    const filtersString = filters?.length
      ? filters
          .map((filter, index) => {
            if (index !== filters.length - 1) return `${filter.slug}|`
            else return filter.slug
          })
          .join("")
      : ""

    // TO DO
    // Instead of history.push, add the filter url to localStorage
    // In gatsby-browser.js if the path change is the search page, then grab url and push it into history
    history.push(
      {
        pathname: `${window.location.pathname}`,
        search: `?p=${page}${query ? `&q=${query}` : ""}&f=${filtersString}`,
      },
      { ...history.location.state, page: page, query: query, filters: filters }
    )
  }

  useEffect(() => {
    console.log("filters:", filters)
  }, [filters])

  useDidMountEffect(() => {
    findResults()
  }, [findResults])

  const emptyQuery = !query.length && !filters.length

  //get params
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search)
      const queryParam = urlParams.get("q")
      const pageParam = urlParams.get("p")

      if (queryParam) {
        setQuery(queryParam)
      }

      if (pageParam) {
        setCurrentPage(parseInt(pageParam))
      }
    }
  }, [])

  return (
    <Layout
      home
      pageData={pageData}
      query={query}
      setQuery={setQuery}
      setCurrentPage={setCurrentPage}
    >

<SEO title="Home"/>

      <HomeTemplateContainer>
        <MainColumn>
          <HomeGrid>
            <Row>
              <Col xs={12} md={3} xsAlignX="start">
                <Filters
                  updateFilters={updateFilters}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Col>
              <RightCol
                hasResults={currentPosts}
                xs={12}
                md={9}
                xsAlignX="start"
              >
                {currentPosts && (
                  <FilterResults
                    filters={filters.length && filters}
                    updateFilters={updateFilters}
                    setCurrentPage={setCurrentPage}
                    setFilters={setFilters}
                    setQuery={setQuery}
                    totalPosts={totalPosts}
                  />
                )}
                {!currentPosts && (
                  <PopularResources
                    popularResources={
                      pageData?.popularResources?.popularResources
                    }
                    findResults={findResults}
                  />
                )}
                {currentPosts && <SearchResults data={currentPosts} />}
                {currentPosts && (
                  <PaginationAndResults>
                    <ResultsCount>{totalPosts} results found.</ResultsCount>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      maxNumbersVisible={isDesktop ? 5 : 3}
                      totalPosts={totalPosts}
                      postsPerPage={postsPerPage}
                      pushToHistory={pushToHistory}
                      query={query}
                      setQuery={setQuery}
                      filters={filters}
                      setFilters={setFilters}
                      scrollId={"header-input"}
                    />
                  </PaginationAndResults>
                )}
              </RightCol>
            </Row>
          </HomeGrid>
        </MainColumn>
      </HomeTemplateContainer>
    </Layout>
  )
}

export default HomeTemplate
