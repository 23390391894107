import React from "react"
import { FilterTab } from "./style"
import { ReactComponent as Cross } from "icons/cross.svg"

const FilterResultTab = ({ filter, updateFilters }) => {
  return (
    <FilterTab onClick={() => updateFilters(filter)}>
      {filter && filter.name}
      <Cross />
    </FilterTab>
  )
}

export default FilterResultTab
