import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "UI/Teaser"
import { Title, Excerpt, InfoWrapper } from "UI/Teaser/style"

export const PopularResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 16px;
    margin-bottom: 15px;
    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .avenueBtn {
    margin-top: 30px;
    @media ${device.desktop} {
      margin-left: auto;
      margin-top: 40px;
    }
  }
`

export const ResourcesTeaser = styled(Teaser)`
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px;
  padding-top: 24px;
  overflow: hidden;

  @media ${device.tablet} {
    height: 100%;
    padding: 30px 34px;
    padding-bottom: 35px;
  }

  ${InfoWrapper} {
    padding-right: 0;
  }

  ${Title} {
    color: #fff;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 37px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      width: 140%;
      height: calc(100% + 55px);
      background-image: linear-gradient(-32deg, #67dbc3 0%, #2e648a 92%);
      z-index: -1;

      @media ${device.desktop} {
        height: calc(100% + 60px);
      }
    }

    @media ${device.desktop} {
      font-size: 22px;
      margin-bottom: 45px;
    }
  }

  ${Excerpt} {
    color: #646f78;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 22px;

    @media ${device.desktop} {
      font-size: 16px;
      margin-bottom: 17px;
      letter-spacing: 0;
    }
  }

  a {
    color: #007e8f;
    font-weight: 500;
    font-size: 12px;
    margin-top: auto;
    display: inline-flex;
    @media ${device.desktop} {
      font-size: 14px;
    }

    svg {
      height: 18px;
      width: 18px;
      margin-right: 8px;
      @media ${device.desktop} {
        height: 20px;
        width: 20px;
        margin-top: -2px;
      }
    }
  }

  :hover {
    a {
      text-decoration: underline;
    }
  }
`
