import React, { useState, useRef } from "react"
import {
  FilterResultsContainer,
  FilterTabsWrapper,
  ResultsCount,
  FilterTab,
  ClearBtn,
} from "./style"
import { Row, Col } from "UI/Grid"
import FilterResultTab from "./FilterResultTab"
import { ReactComponent as Cross } from "icons/cross.svg"

const FilterResults = ({
  updateFilters,
  filters,
  setFilters,
  totalPosts,
  setCurrentPage,
  setQuery
}) => {
  return (
    <FilterResultsContainer hasFilters={filters.length}>
      <Row mdGap={21}>
        <Col xs={12}>
          <ResultsCount>
            {totalPosts} Results found.{" "}
            {filters.length && <span>Filtering by:</span>}
          </ResultsCount>
        </Col>
        {filters ? (
          <Col xs={12} md={10}>
            <FilterTabsWrapper>
              {filters.map((filter, index) => {
                return (
                  <FilterResultTab
                    key={index}
                    filter={filter}
                    updateFilters={updateFilters}
                  />
                )
              })}
            </FilterTabsWrapper>
          </Col>
        ) : null}
        {filters ? (
          <Col xs={12} md={2} mdAlignY="center">
            <ClearBtn
              onClick={() => {
                setCurrentPage(1)
                setFilters([])
                setQuery("")
              }}
            >
              Clear all filters <Cross />
            </ClearBtn>
          </Col>
        ) : null}
      </Row>
    </FilterResultsContainer>
  )
}

export default FilterResults
