import React from "react"
import { graphql } from "gatsby"
import { HomeTemplate } from "../components/Templates/HomeTemplate"

const Home = ({ pageContext }) => {
  const { title } = pageContext

  console.log(pageContext)
  return <HomeTemplate pageData={pageContext} />
}


export default Home
