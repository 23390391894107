import styled from "styled-components"
import { WhiteButton } from "components/globalComponents"
import { device } from "breakpoints"
export const PaginationContainer = styled.div`
  display: flex;
  opacity: ${(props) => (props.noResults || props.hide ? "0" : "1")};
  transition: 140ms;
  align-items: center;
  margin: 0 auto;

  @media ${device.desktop} {
    margin-right: 0;
    margin-left: auto;
  }
`
export const PageNumberList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 15px;
`

export const ListItem = styled.li`
  display: ${(props) => (props.hidden ? "none" : "block")};
  padding: 0 0px;
  position: relative;

  @media ${device.desktop} {
    padding: 0 3px;
  }
`

export const Number = styled.button`
  outline: none;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  color: ${(props) => (props.active ? "white" : "#313B44")};
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.active
      ? "linear-gradient(132deg, #41BCA3 6%, #245A7F 100%);"
      : "none"};
  border: none;
  border-radius: 50%;
  transition: 80ms;

  @media ${device.desktop} {
    font-size: 20px;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :hover {
    color: ${(props) => !props.active && props.theme.teal};
  }
`

export const Skip = styled(Number)`
  height: auto;
  width: auto;
`

export const NextButton = styled(WhiteButton)`
  pointer-events: ${(props) => props.disabled && "none"};
  /* opacity: ${(props) => (props.disabled ? 0.3 : 1)}; */
  background: ${(props) => props.disabled && "#C4CFD7"};
  background-image: ${(props) => props.disabled && "none"};
  color: ${(props) => props.disabled && "#C4CFD7"};
  width:60px;
  font-size:12px;
  height:40px;

  @media (min-width: 390px) {
    width:80px;
    margin-left: 10px;
    font-size: 14px;
    height:48px;

  }
  @media ${device.desktop} {
    width: 100px;
    height:60px;
    margin-left:5px;
  }
  span {
    line-height: 100%;
  }
`

export const PreviousButton = styled(NextButton)`
  margin-left: 0;
  @media (min-width: 380px) {
    margin-right: 10px;
  }

  @media ${device.desktop} {
    margin-left: 0px;
    margin-right: 5px;
  }
`
