import React, { useEffect, useState } from "react"
import { FilterItemButton, FilterListItem } from "./style"
const FilterItem = ({
  className,
  filter,
  children,
  hasSecondLevel,
  updateFilters,
  filters,
}) => {
  const [selected, setSelected] = useState(false)
  // console.log("filter:", filter)

  const handleClick = () => {
    updateFilters && updateFilters(filter)
    setSelected(!selected)
  }

  useEffect(() => {
    if (filters?.find((item) => item?.slug === filter?.slug)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [filters, filter])
  return (
    <FilterListItem className={className} selected={selected}>
      <FilterItemButton
        selected={selected}
        onClick={() => handleClick()}
        hasSecondLevel={hasSecondLevel}
      >
        {filter && <span>{filter.name}</span>}
      </FilterItemButton>
      {children}
    </FilterListItem>
  )
}

export default FilterItem
