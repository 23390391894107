import React, { useState } from "react"
import { ReactComponent as Arrow } from "icons/arrow-in-circle.svg"
import { SelectInput } from "UI/SelectInput"
import FilterItem from "./FilterItem"
import {
  FiltersSectionContainer,
  FiltersSectionTitle,
  FilterSectionList,
  TypeList,
} from "./style"

const FiltersSection = ({
  list,
  title,
  other,
  select,
  updateFilters,
  filters,
  filtersList,
  data,
}) => {
  const [open, setOpen] = useState(true)
  const selectOptions =
    data?.map((option) => ({
      label: option.name,
      value: option.slug,
      type: option.type,
    })) || []

  let selectedPlaceholder
  let selectedFilters = []

  // Pre-select select boxes if they exist in the query string
  filters.forEach((filter) => {
    selectedFilters.push({
      label: filter.slug,
      value: filter.slug,
    })

    const result = selectOptions.filter((item) => item.value == filter.slug)

    if (result.length) {
      selectedPlaceholder = filter.slug
    }
  })

  const handleSelect = (e) => {
    updateFilters &&
      updateFilters({
        name: e.label,
        slug: e.value,
        type: e.type,
      })
  }

  return (
    <FiltersSectionContainer>
      <FiltersSectionTitle
        select={select}
        open={open}
        onClick={() => {
          !select && setOpen(!open)
        }}
      >
        {title} {!select && <Arrow />}
      </FiltersSectionTitle>
      {other ? (
        <TypeList open={open}>
          {filtersList?.map((item, index) => {
            return (
              <FilterItem
                key={index}
                filter={{ ...item }}
                updateFilters={updateFilters}
                filters={filters}
              />
            )
          })}
        </TypeList>
      ) : list ? (
        <FilterSectionList open={open}>
          {filtersList?.map((item, index) => {
            const hasSecondLevel = item?.wpChildren?.nodes?.length
            return (
              <FilterItem
                key={index}
                filter={{ ...item, type: "category" }}
                updateFilters={updateFilters}
                filters={filters}
                hasSecondLevel={hasSecondLevel}
              >
                {hasSecondLevel ? (
                  <ul>
                    {item.wpChildren.nodes.map((nestedItem, index) => {
                      return (
                        <FilterItem
                          key={index}
                          filter={{ ...nestedItem, type: "category" }}
                          updateFilters={updateFilters}
                          filters={filters}
                        />
                      )
                    })}
                  </ul>
                ) : null}
              </FilterItem>
            )
          })}
        </FilterSectionList>
      ) : select ? (
        <div className="selectWrapper">
          <SelectInput
            options={selectOptions}
            selectedFilters={selectedFilters}
            placeholder={selectedPlaceholder || "View all"}
            onChange={handleSelect}
          />
        </div>
      ) : null}
    </FiltersSectionContainer>
  )
}
export default FiltersSection
