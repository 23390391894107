import styled from "styled-components"
import { device } from "breakpoints"

export const FiltersContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;

  @media ${device.desktop} {
    margin-top: -14px;
  }

  &:before {
    content: "";
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: black;
    opacity: 0.6;
    z-index: -1;
    display: ${(props) => (props.filtersShown ? "block" : "none")};

    @media ${device.desktop} {
      display: none;
    }
  }
`

export const TriggerAndTitle = styled.button`
  display: flex;
  font-size: 16px;
  color: ${(props) => props.theme.green};
  border-bottom: 2px solid #4ea3b3;
  padding: 12px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  position: relative;
  z-index: 2;
  @media ${device.desktop} {
    font-size: 18px;
    color: #303b45;
    border: none;
    padding-bottom: 17px;
    justify-content: flex-start;
    cursor: inherit;
  }

  > svg {
    width: 22px;
    height: 22px;
    transform: rotate(90deg);
    margin-left: 10px;

    @media ${device.desktop} {
      display: none;
    }
  }

  > svg.desktop-arrow {
    display: none;
    @media ${device.desktop} {
      display: block !important;
      transform: none;
      height: 10px;
      width: 6px;
      margin: 0;
      margin-right: 10px;
      margin-top: -2px;
    }
  }
`

export const FiltersListWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;
  padding: 0 24px;
  padding-top: 80px;
  transform: translateX(-50%);
  width: calc(100vw - 13px);
  background: #fff;
  display: none;
  display: ${(props) => props.filtersShown && "flex"};
  flex-direction: column;
  box-shadow: 0 12px 65px 0 rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  /* z-index: -1; */
  margin-bottom: 70px;

  @media ${device.desktop} {
    display: flex;
    position: static;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    padding: 0;
    margin-bottom: 0px;

    box-shadow: none;
    background: none;
  }
`

export const FiltersSectionContainer = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;

  @media ${device.large} {
    margin-bottom: 30px;
  }

  li {
    position: relative;
  }

  > * {
    border: 1px solid #c4cfd7;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  > div.selectWrapper {
    padding-top: 20px;
    padding-bottom: 25px;
  }
`

export const FiltersSectionTitle = styled(TriggerAndTitle)`
  background-image: linear-gradient(-27deg, #67dbc3 0%, #2e648a 81%);
  border-radius: ${(props) => (props.open ? "10px 10px 0 0" : "10px")};
  color: white;
  font-size: 14px;
  padding: 20px;
  font-weight: bold;
  border: none;
  text-align: left;
  cursor: ${(props) => props.select && "inherit"} !important;

  @media ${device.desktop} {
    justify-content: space-between;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }

  @media ${device.large} {
    font-size: 16px;
  }
  svg {
    transform: ${(props) => props.open && "rotate(-90deg) scale(1)"};
    display: block;
    transition: 90ms;
    opacity: 1;
    g {
      stroke: white;
    }
  }

  :hover {
    svg {
      opacity: 0.7;
    }
  }
`

export const FilterSectionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 22px;
  padding-top: 15px;
  padding-bottom: 17px;
  display: ${(props) => (props.open ? "block" : "none")};

  h5 {
    font-size: 14px;
    color: ${(props) => props.theme.green};
    margin-bottom: 8px;
  }

  li {
    position: relative;
    color: #2c394a;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0;
    padding-left: 16px;

    ::before {
      content: "";
      height: 6px;
      width: 6px;
      background-color: #42bba3;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 14px;
      left: 0px;
      z-index: 4;

      @media ${device.desktop} {
        top: 14px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding-top: 12px;

      li {
        font-size: 12px;
        padding: 5px 0;
        padding-left: 15px;
        ::before {
          content: "";
          height: 4px;
          width: 4px;
          top: 12px;
        }

        button {
          &:before {
            height: 85%;
          }
        }
      }
    }
  }
`

export const TypeList = styled.ul`
  list-style: none;
  display: ${(props) => (props.open ? "block" : "none")};

  li {
    color: ${(props) => props.theme.green};
    font-size: 14px;
    font-weight: 500;
    padding: 20px 24px;
    border-bottom: 1px solid #c4cfd7;

    :last-of-type {
      border: none;
    }
  }

  button {
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: calc(100% - 20px);
      height: 60%;
      z-index: 0;
      border-radius: 18px;
      background: ${(props) => props.selected && "#4EA3B3"};
    }
  }
`

export const FilterItemButton = styled.button`
  text-align: left;
  color: inherit;
  color: ${(props) => props.selected && "white"} !important;
  width: 100%;
  height: 100%;
  line-height: 130%;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% + 25px);
    height: 90%;
    z-index: 0;
    border-radius: 18px;
    background: ${(props) => props.selected && "#4EA3B3"};
  }

  span {
    z-index: 1;
    position: relative;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  ${(props) =>
    props.hasSecondLevel &&
    `
      &:before, &:after {
        top:2px;
        left:50%;
        transform:translate3d(-50%, 0, 0);
        height:32px;
      }
    
    `}
`

export const FilterListItem = styled.li`
  &:before {
    background-color: ${(props) => props.selected && "#fff"} !important;
  }

  :hover {
    color: ${(props) => props.theme.teal};
  }
`
