import styled from "styled-components"
import { device } from "breakpoints"
import { Grid } from "UI/Grid"
import { FiltersSectionTitle } from "../style"
import FilterItem from "../FilterItem"
export const AuthorModuleContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  @media ${device.large} {
    margin-bottom: 30px;
  }
  ${FiltersSectionTitle} {
    height: 60px;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 68px;
      opacity: 0.5;
      background: #3c8d9d;
      border-radius: inherit;
      border-radius: inherit;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    svg {
      height: 32px;
      width: 32px;
      transform: none;
      z-index: 2;
      flex-shrink: 0;
    }
  }
`

export const AuthorGrid = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  display: ${(props) => (props.open ? "block" : "none")};
  @media ${device.desktop} {
    position: absolute;
    right: -30px;
    width: calc(73vw - 40px);
    transform: translateX(100%);
    margin: 0;
    top: 0;
    border: 1px solid #c4cfd7;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    background: white;
    padding: 35px 40px;
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      transform: translateX(-20%) rotate(45deg);
      width: 24px;
      height: 24px;
      background: white;
      left: 0;
    }
  }

  @media ${device.large} {
    right: -28px;
    width: 897px;
  }

  div.row {
    flex-flow: column wrap;
    ${'' /* height: 600px; */}

    display: block;
    column-count: 2;

    > div {
      width: 50%;

      @media (min-width: 400px) {
        width: auto;
      }
    }

    @media ${device.tablet} {
      ${'' /* height: 400px; */}
    }

    @media ${device.desktop} {
      ${'' /* height: 400px; */}
      column-count: 3;
    }
  }
`

export const AuthorItem = styled(FilterItem)`
  list-style: none;
  position: relative;

  @media ${device.desktop} {
    max-width: 260px;
  }
  button {
    color: #636e78;
    font-size: 12px;
    font-weight: 500;

    @media ${device.desktop} {
      font-size: 14px;
    }

    &:before {
      height: 130%;
    }
  }

  :hover {
    button {
      color: ${(props) => props.theme.teal};
    }
  }
`
