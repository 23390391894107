import styled from "styled-components"
import { device } from "breakpoints"
import { Grid } from "UI/Grid"

export const FilterResultsContainer = styled(Grid)`
  padding-bottom: 19px;
  border-bottom: 2px solid #4ea3b3;

  @media ${device.desktop} {
    padding-bottom: 14px;
    padding-bottom: ${(props) => !props.hasFilters && "22px"};
  }
`

export const ResultsCount = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #303b45;

  span {
    font-weight: normal;
    color: #9ba3a9;
  }

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`
export const FilterTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const FilterTab = styled.button`
  display: flex;
  background: #4ea3b3;
  border-radius: 18px;
  height: 28px;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  line-height: 100%;
  padding-left: 10px;
  margin-right: 10px;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  opacity: 1;
  transition: 80ms;

  @media ${device.desktop} {
    font-size: 12px;
    height: 36px;
    padding-left: 15px;
    margin-right: 15px;
  }

  svg {
    height: 10px;
    width: 10px;
    margin: 0 10px;
    margin-left: 17px;
    position: relative;
    z-index: 1;

    @media ${device.desktop} {
      height: 11px;
      width: 11px;
      margin: 15px;
      margin-left: 22px;
    }
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 28px;
    background: #3c8d9d;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @media ${device.desktop} {
      width: 38px;
    }
  }

  :hover {
    background: #3c8d9d;
  }
`

export const ClearBtn = styled.button`
  font-weight: 500;
  font-size: 12px;
  color: #9ba3a9;
  align-self: flex-start;
  display: flex;
  align-items: center;
  line-height: 100%;
  transition: 90ms;
  @media ${device.desktop} {
    margin-top: 0;
    align-self: flex-end;
  }

  svg {
    height: 11px;
    width: 11px;
    margin-left: 7px;

    path {
      fill: #e26776;
    }
  }

  :hover {
    color: ${(props) => props.theme.teal};
  }
`
